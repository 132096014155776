import { WindowLocation } from "@reach/router"
import { Seo } from "components/fragments/Seo/Seo"
import { ContentfulInsight, InsightView } from "components/views/InsightView/InsightView"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { helmetJsonLdProp } from "react-schemaorg"
import { Article } from "schema-dts"

interface Location extends WindowLocation {
  state: {
    fromCategory?: {
      id: string
      name: string
    }
  }
}

interface InsightProps {
  data: {
    allContentfulNews: {
      nodes: ContentfulInsight[]
    }
    contentfulNews: ContentfulInsight
  }
  location: Location
}

const Insight = ({ data, location }: InsightProps): JSX.Element => {
  return (
    <>
      <Seo
        description={data.contentfulNews.metaDescription}
        image={
          data.contentfulNews.metaImage?.media.gatsbyImageData ??
          data.contentfulNews.coverImage?.media.gatsbyImageData ??
          data.contentfulNews.featuredImage?.gatsbyImageData
        }
        pathname={location.pathname}
        title={data.contentfulNews.metaTitle}
        noIndexNoFollow={data.contentfulNews.metaNoIndexNoFollow ?? false}
      />
      <Helmet
        script={[
          helmetJsonLdProp<Article>({
            "@context": "https://schema.org",
            "@type": "Article",
            abstract: data.contentfulNews?.excerpt?.excerpt,
            headline: data.contentfulNews.title,
            author: data.contentfulNews.author,
            ...(data.contentfulNews.metaImage && {
              thumbnailUrl: `https:${
                data.contentfulNews.metaImage.media.file.url ??
                data.contentfulNews.coverImage.media.file.url ??
                data.contentfulNews.featuredImage.file.url
              }`,
            }),
            datePublished: new Date(data.contentfulNews.schemaPublishDate).toISOString(),
            sourceOrganization: {
              "@type": "Organization",
              name: "Einride",
            },
          }),
        ]}
      />
      <InsightView
        location={location}
        insight={data.contentfulNews}
        similar={data.allContentfulNews.nodes}
      />
    </>
  )
}

export const query = graphql`
  query ($categories: [String!], $id: String!, $channel: String!) {
    allContentfulNews(
      filter: {
        categories: {
          elemMatch: {
            id: { in: $categories }
            metadata: { tags: { elemMatch: { contentful_id: { eq: $channel } } } }
          }
        }
        hidden: { ne: true }
        id: { ne: $id }
        metaNoIndexNoFollow: { ne: true }
      }
      sort: { fields: publishDate, order: DESC }
      limit: 8
    ) {
      nodes {
        ...ContentfulNews
      }
    }
    contentfulNews(id: { eq: $id }) {
      author
      showAuthor
      body {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            file {
              contentType
              url
            }
            gatsbyImageData(layout: FULL_WIDTH)
            title
          }
          ... on ContentfulMediaKit {
            __typename
            contentful_id
            fileUrl
            thumbnail {
              gatsbyImageData
            }
            title
          }
          ... on ContentfulNews {
            __typename
            contentful_id
            slug
            title
          }
          ... on ContentfulPressRelease {
            __typename
            contentful_id
            slug
            title
          }
          ... on ContentfulQuote {
            __typename
            contentful_id
            name
            quote {
              raw
            }
            author
            jobFunction
          }
          ... on ContentfulImage {
            __typename
            contentful_id
            ...ContentfulImage
          }
        }
      }
      categories {
        ...ContentfulCategory
      }
      excerpt {
        excerpt
      }
      featuredImage {
        gatsbyImageData(width: 820, layout: FULL_WIDTH)
        description
      }
      coverImage {
        ... on ContentfulImage {
          __typename
          altText
          media {
            gatsbyImageData(width: 820, layout: FULL_WIDTH)
            file {
              url
            }
            description
          }
        }
      }
      metaDescription
      metaImage {
        ... on ContentfulImage {
          __typename
          altText
          media {
            gatsbyImageData(width: 1200, height: 630)
            file {
              url
            }
            description
          }
        }
      }
      metaTitle
      metaNoIndexNoFollow
      publishDate(formatString: "M.D.YYYY")
      schemaPublishDate: publishDate(formatString: "YYYY/MM/DD")
      hidePublicationDate
      title
      highlightsTitle
    }
  }
`

export default Insight
