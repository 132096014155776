import { Icon } from "@einride/ui"
import styled from "@emotion/styled"
import { ContentfulInsight } from "components/views/InsightView/InsightView"
import { Link } from "gatsby"
import { useState } from "react"

interface InsightArticleProps {
  insight: ContentfulInsight
  location: Location
}

export const ShareList = ({ location, insight }: InsightArticleProps): JSX.Element => {
  const [copied, setCopied] = useState(false)
  const [shareVisible, setShareVisible] = useState(false)

  const handleCopy = (): void => {
    const articleUrl = location.href
    navigator.clipboard
      .writeText(articleUrl)
      .then(() => {
        setCopied(true)
      })
      .catch((e: Error) => {
        setCopied(false)
        throw new Error(`Couldn't copy article URL: ${e.name}\n${e.message}`)
      })
  }

  const handleOpenShare = (): void => {
    setShareVisible(!shareVisible)
  }

  return (
    <Wrapper>
      <ShareButton type="button" onClick={handleOpenShare}>
        Share
        <ShareButtonIcon name="arrowUp" />
      </ShareButton>

      <ShareOptionsList shareVisible={shareVisible}>
        <ShareOption>
          <a
            href={`mailto:?subject=Check out this article "${encodeURIComponent(
              insight.title,
            )}"&body=Hello, check out this article I found on ${location.href}.`}
          >
            Email
          </a>
        </ShareOption>
        <ShareOption>
          <a
            href={`https://www.linkedin.com/shareArticle?mini=true&title=${encodeURIComponent(
              insight.title,
            )}&url=${location.href}`}
            target="_blank"
            rel="noreferrer"
          >
            Linkedin
          </a>
        </ShareOption>
        <ShareOption>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`}
            target="_blank"
            rel="noreferrer"
          >
            Facebook
          </a>
        </ShareOption>
        <ShareOption>
          <a
            href={`https://twitter.com/intent/tweet?url=${location.href}&text=${encodeURIComponent(
              insight.title,
            )}`}
            target="_blank"
            rel="noreferrer"
          >
            X
          </a>
        </ShareOption>
        <ShareOption>
          <button type="button" onClick={handleCopy}>
            {copied ? "Copied" : "Copy url"}
          </button>
        </ShareOption>
        <ShareOption>
          <Link to="#subscribe">Subscribe</Link>
        </ShareOption>
      </ShareOptionsList>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  @media ${({ theme }) => theme.mediaQueries.md} {
    position: sticky;
    top: 0;
    padding-top: 40px;
  }
`

const ShareOptionsList = styled.ul<{ shareVisible: boolean }>`
  list-style: none;

  @media ${({ theme }) => theme.mediaQueries.belowMd} {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 20px 8px;
    text-align: left;
    transform: translateX(0) translateY(100%);
    visibility: hidden;
    background: ${({ theme }) => theme.colors.background.primary};
    ${({ shareVisible }) => shareVisible && "visibility: visible"};
  }
`

const ShareOption = styled.li`
  a,
  button {
    color: ${({ theme }) => theme.colors.content.secondary};
  }

  a:hover,
  button:hover {
    color: ${({ theme }) => theme.colors.content.primary};
  }
`

const ShareButton = styled.button`
  @media ${({ theme }) => theme.mediaQueries.belowMd} {
    text-decoration: underline;
  }
`

const ShareButtonIcon = styled(Icon)`
  display: inline-block;
  margin-left: 4px;
  transform: rotate(45deg);
  @media ${({ theme }) => theme.mediaQueries.md} {
    display: none;
  }
`
