import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"
import { Title2 } from "@einride/ui"
import styled from "@emotion/styled"
import { RichText } from "components/contentful-ui/RichText/RichText"
import { ContentWrapper, HideAbove } from "components/shared/styles/Layout"
import { getInsightAlt } from "components/ui/InsightCard/InsightCard"
import { Link } from "components/ui/Link/Link"
import { ContentfulInsight } from "components/views/InsightView/InsightView"
import { GatsbyImage } from "gatsby-plugin-image"

import { mediaQueries } from "lib/theming/breakpoint"
import { Paragraph } from "lib/ui/Typography/Typography"
import type { ContentfulCategory } from "types/contentful"
import { ShareList } from "./ShareList/ShareList"
// import { TextToSpeech } from "./TextToSpeech/TextToSpeech"

interface InsightArticleProps {
  insight: ContentfulInsight
  location: Location
}

export const InsightArticle = ({ insight, location }: InsightArticleProps): JSX.Element => {
  const insightCategory =
    insight.categories &&
    insight.categories.find((item: ContentfulCategory) => {
      if (
        item?.metadata?.tags.find((tag) => tag.contentful_id === process.env.GATSBY_SITE_CHANNEL)
      ) {
        return item
      }
      return null
    })
  const readingTime = (): number => {
    const plainText = documentToPlainTextString(JSON.parse(insight.body.raw))

    const wpm = 225
    const words = plainText.trim().split(/\s+/).length
    const time = Math.ceil(words / wpm)

    return time
  }

  return (
    <ContentWrapper>
      <Article>
        <ImageContainer>
          {insight.coverImage ? (
            <GatsbyImage
              image={insight.coverImage.media.gatsbyImageData}
              alt={insight.coverImage.altText ? insight.coverImage.altText : insight.title}
            />
          ) : (
            insight.featuredImage && (
              <GatsbyImage
                image={insight.featuredImage.gatsbyImageData}
                alt={getInsightAlt(insight)}
              />
            )
          )}
        </ImageContainer>

        <Heading>
          <CardMeta>
            <div>
              {insightCategory && (
                <Link
                  key={insightCategory.name}
                  to={`/${process.env.GATSBY_SITE_INSIGHTS_URL}/category/${insightCategory.slug}`}
                >
                  <StyledParagraph as="span">{insightCategory.name ?? ""}</StyledParagraph>
                </Link>
              )}

              {insight.hidePublicationDate !== true && (
                <StyledParagraph as="span" color="secondary">
                  {insight.publishDate}
                </StyledParagraph>
              )}
            </div>
            {insight.body && <span>{readingTime()} min read</span>}
          </CardMeta>
          <CardTitle>{insight.title}</CardTitle>
        </Heading>

        <Actions>
          <CardMeta>
            {/* <TextToSpeech textToRead={insight.body.raw} title={insight.title} /> */}
            <ShareList location={location} insight={insight} />
          </CardMeta>
        </Actions>

        <Content>
          <HideAbove breakpoint={1279}>
            <CardMeta>
              <div>
                {insightCategory && (
                  <Link
                    key={insightCategory.name}
                    to={`/${process.env.GATSBY_SITE_INSIGHTS_URL}/category/${insightCategory.slug}`}
                  >
                    <StyledParagraph as="span">{insightCategory.name ?? ""}</StyledParagraph>
                  </Link>
                )}

                {insight.hidePublicationDate !== true && (
                  <StyledParagraph as="span" color="secondary">
                    {insight.publishDate}
                  </StyledParagraph>
                )}
              </div>
              {insight.body && <span>{readingTime()} min read</span>}
            </CardMeta>
            <CardTitle>{insight.title}</CardTitle>
          </HideAbove>
          <RichText body={insight.body} />
        </Content>
      </Article>
    </ContentWrapper>
  )
}

const CardMeta = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => 2 * theme.spacer}px;
`

const Article = styled.div`
  margin: 0 -12px;
  @media ${({ theme }) => theme.mediaQueries.md} {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  padding: 0 12px;
  margin-bottom: ${({ theme }) => 1.5 * theme.spacer}px;
  display: flex;
  height: 53vh;

  .gatsby-image-wrapper {
    width: 100%;
  }

  @media ${mediaQueries.md} {
    height: 65vh;
  }

  @media ${({ theme }) => theme.mediaQueries.lg} {
    width: ${(100 / 12) * 6}%;
    height: 80vh;
  }
`

const Actions = styled.div`
  padding: 0 12px;
  position: relative;
  @media ${({ theme }) => theme.mediaQueries.md} {
    width: ${(100 / 8) * 2}%;

    display: flex;

    ${CardMeta} {
      display: block;
    }
  }

  @media ${({ theme }) => theme.mediaQueries.lg} {
    width: ${(100 / 12) * 6}%;
  }
`

const Heading = styled.div`
  display: none;
  padding: 0 12px;
  @media ${({ theme }) => theme.mediaQueries.md} {
    width: ${(100 / 8) * 6}%;
  }
  @media ${({ theme }) => theme.mediaQueries.lg} {
    display: block;
    width: ${(100 / 12) * 6}%;
    align-self: flex-end;
  }
`

const Content = styled.div`
  padding: 0 12px;

  @media ${({ theme }) => theme.mediaQueries.md} {
    width: ${(100 / 8) * 6}%;
  }
  @media ${({ theme }) => theme.mediaQueries.lg} {
    width: ${(100 / 12) * 6}%;
  }
`

const CardTitle = styled(Title2)`
  margin-bottom: ${({ theme }) => 1 * theme.spacer}px;

  @media ${({ theme }) => theme.mediaQueries.lg} {
    font-size: ${({ theme }) => theme.fontSizes["2xl"]}};
    line-height: 1.2;    
  }
`

const StyledParagraph = styled(Paragraph)`
  font-variant-numeric: slashed-zero;
  margin-right: 8px;
`
