import { VerticalSpacing } from "@einride/ui"
import { WindowLocation } from "@reach/router"
import { InsightArticle } from "components/ui/InsightArticle/InsightArticle"
// import { VoicePlayer } from "components/ui/InsightArticle/VoicePlayer/VoicePlayer"
import { Layout } from "components/ui/Layout/Layout"
import { SimilarInsights } from "components/ui/SimilarInsights/SimilarInsights"
// import { VoicePlayerProvider } from "context/VoicePlayerContext"
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from "gatsby-source-contentful/rich-text"
// import { useState } from "react"
import type { ContentfulCategory, ContentfulMediaAsset } from "types/contentful"

import { getLatestPageData, pushToDatalayer } from "utils/tracking"
import { InsightRectangleCTA } from "../InsightsView/components/InsightRectangleCTA/InsightRectangleCTA"

export interface ContentfulInsight {
  __typename: "ContentfulNews"
  id: string
  metaDescription: string
  metaTitle: string
  metaImage: {
    altText: string
    media: ContentfulMediaAsset
  }
  metaNoIndexNoFollow?: boolean
  author: string
  showAuthor?: boolean
  body: RenderRichTextData<ContentfulRichTextGatsbyReference>
  categories: ContentfulCategory[]
  slug: string
  featuredImage: ContentfulMediaAsset
  coverImage: {
    altText: string
    media: ContentfulMediaAsset
  }
  feedImage: {
    altText: string
    media: ContentfulMediaAsset
  }
  hideImageInFeed?: boolean
  publishDate: string
  schemaPublishDate: string
  hidePublicationDate: boolean
  title: string
  highlightsTitle: string
  excerpt: {
    excerpt: string
  }
}

interface Location extends WindowLocation {
  state: {
    fromCategory?: {
      id: string
      name: string
    }
  }
}

interface InsightViewProps {
  insight: ContentfulInsight
  location: Location
  similar: ContentfulInsight[]
}

export const InsightView = ({ insight, location, similar }: InsightViewProps): JSX.Element => {
  const { title, categories, showAuthor, author } = insight

  pushToDatalayer({
    event: "article_page_view",
    contentDetails: {
      article_headline: title ?? undefined,
      article_category: categories
        ? categories.map((category) => category.name) ?? undefined
        : undefined,
      article_author: showAuthor ? author ?? undefined : undefined,
      pageData: getLatestPageData(),
    },
  })

  /* const [voicePlayerData, setVoicePlayerData] = useState({
    isInitialised: false,
    isPlaying: false,
    hasLoaded: false,
    playerText: "Listen to article",
  }) */

  const insightCategory: ContentfulCategory = insight.categories.find(
    (item: ContentfulCategory) => {
      return item?.metadata?.tags.find(
        (tag) => tag.contentful_id === process.env.GATSBY_SITE_CHANNEL,
      )
    },
  )

  return (
    <Layout>
      <InsightArticle location={location} insight={insight} />
      <VerticalSpacing size="xl" />
      <InsightRectangleCTA position="insights-feed-footer" />
      <VerticalSpacing size="xl" />
      {insightCategory && (
        <SimilarInsights
          insights={similar}
          category={insightCategory}
          hideDiscoverMore={insightCategory.hideDiscoverMore}
          hideDiscoverAll={insightCategory.name.toLowerCase().includes("speak")}
        />
      )}

      {/* voicePlayerData.isInitialised && <VoicePlayer /> */}
    </Layout>
  )
}
