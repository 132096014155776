import { Icon, Title1 } from "@einride/ui"
import styled from "@emotion/styled"
import { ContentWrapper, InsightCardsWrapper } from "components/shared/styles/Layout"
import { ContentfulInsight } from "components/views/InsightView/InsightView"
import { ContentfulCategory } from "types/contentful"
import { InsightCard } from "../InsightCard/InsightCard"
import { Link } from "../Link/Link"

interface SimilarInsightsProps {
  insights: ContentfulInsight[]
  category: ContentfulCategory
  hideDiscoverMore?: boolean
  hideDiscoverAll?: boolean
}

export const SimilarInsights = ({
  insights,
  category,
  hideDiscoverMore = false,
  hideDiscoverAll = false,
}: SimilarInsightsProps): JSX.Element | null => {
  if (!insights.length) {
    return null
  }
  return (
    <ContentWrapper>
      {!hideDiscoverMore && (
        <>
          <Title>{`${category.labelDiscoverMore} ${category.name}`}</Title>
          <StyledInsightCardsWrapper>
            {insights.map((insight) => (
              <InsightCard
                insight={insight}
                key={insight.id}
                hideCategoryLabel={category !== undefined}
              />
            ))}
          </StyledInsightCardsWrapper>
        </>
      )}

      {!hideDiscoverAll && (
        <AllInsightsTitle>
          <Link to={`/${process.env.GATSBY_SITE_INSIGHTS_URL}`}>
            {`Discover all ${process.env.GATSBY_SITE_INSIGHTS_URL}`} <Icon name="arrowRight" />
          </Link>
        </AllInsightsTitle>
      )}
    </ContentWrapper>
  )
}

const StyledInsightCardsWrapper = styled(InsightCardsWrapper)`
  margin-top: 39px;
  margin-bottom: 52px;
  @media ${({ theme }) => theme.mediaQueries.lg} {
    margin-top: 55px;
    margin-bottom: 42px;
  }
`

const Title = styled(Title1)`
  @media ${({ theme }) => theme.mediaQueries.belowLg} {
    font-size: ${({ theme }) => theme.fontSizes.xl};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    line-height: calc(8 / 7);
    margin-top: 6px;
    margin-bottom: 2px;
  }
  @media ${({ theme }) => theme.mediaQueries.lg} {
    width: 50%;
    padding: 0 12px;
    margin-left: 50%;
  }
`

const AllInsightsTitle = styled(Title)`
  margin-bottom: 79px;
  @media ${({ theme }) => theme.mediaQueries.belowLg} {
    margin-bottom: 63px;
  }
  @media ${({ theme }) => theme.mediaQueries.belowMd} {
    margin-bottom: 39px;
  }
`
